// i18next-extract-mark-ns-start terms
import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageSection from "../../components/page-section"
import ClientOnly from "../../components/client-only"

import ToursCalendarList from "../../components/tour-calendar-list/tour-calendar-list"
import ToursList from '../../components/tour-calendar-list/list/ToursList';
import { useDispatch } from "react-redux"
import { Dispatch } from "../../store/store"
import { useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { DateTime } from "luxon"


const VistulaDayEventsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    dispatch.toursCalendar.initialize({ translate: t, initDate: DateTime.fromISO("2022-09-03"), filterByProject: "SW_22", });
  }, [])

  return (
    <Layout>
      <Seo title={"Święto Wisły 2022 - Atrakcje"} />
      <PageSection>
        <h1 className="pb-5">Atrakcje biletowane</h1>
        <div className="py-2" ></div>
        <p>Poniżej znajdują się atrakcje na które można zarezerwować bilety.</p>
        <p>Zarezerwowane <strong>bilety należy odebrać wcześniej</strong> w dniu wydarzenia (<strong>najpóźniej na 10 min przed</strong>), w miejscu rozpoczęcia atrakcji.</p>
      </PageSection>
      <PageSection id="toursCalendarSection" className="bg-white text-black position-relative" style={{minHeight:400}}>
        <ClientOnly>
          <ToursList />
        </ClientOnly>
      </PageSection>
    </Layout>
  )

}

export default VistulaDayEventsPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: [ "navigation", "footbar" ]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;